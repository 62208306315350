import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import happycbg from "../images/happycbg.svg";
import moonland from "../images/moonland.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import zethan from "../images/zethan.svg";
import betterphysiology from "../images/betterphysiology.svg";
import { Link } from "react-router-dom";
import c_box from "../images/c_box.png";
import c_boxh from "../images/c_boxh.png";
import web_icon from "../images/icons8-web-development-64.png";
import software_testing from "../images/icons8-software-testing-64.png";
import web_3 from "../images/web-3-icon.svg";
import block_chain from "../images/icons8-block-chain-64.png";
import marketing from "../images/one_marketing.png";
import devops from "../images/devops-60.png";

const useStyles = makeStyles({
  Main__div: {
    overflow: "hidden",
    background: `url(${happycbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    padding: "4rem 0rem 2rem 0rem",
    "@media(max-width : 1200px)": {
      backgroundSize: "cover",
      padding: "2rem 0rem 2rem 0rem",
    },
  },
  clients: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(max-width : 600px)": {
      justifyContent: "space-around",
      flexWrap: "wrap",
      gap: "2rem",
    },
  },
  clientHding: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "1.5rem",
    "@media(max-width : 600px)": {
      display: "inherit",
      textAlign: "center",
    },
  },
  line: {
    backgroundColor: "#253047",
    width: "4rem",
    height: "1px",
    marginLeft: "1.5rem",
    "@media(max-width : 600px)": {
      width: "100%",
      margin: "1rem 0rem",
    },
  },
  c_box_one: {
    background: `url(${c_box})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "inline-block",
    padding: "1.6rem 2rem",
    textDecoration: "none",
    width: "15%",
    textAlign: "center",
    color: "#000",
    transition: "0.5s",
    "&:hover": {
      background: `url(${c_boxh})`,
      color: "#fff",
      backgroundSize: "100%",
    },
    "@media(max-width : 1200px)": {
      padding: "1.6rem 0.6rem",
      width: "160px",
      "@media(max-width : 900px)": {
        width: "180px",
        "@media(max-width : 600px)": {
          textAlign: "center",
          width: "100%",
          "&:hover": {
            width: "100%",
          },
        },
      },
    },
  },
  client__box_item: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-10.5rem",
    position: "absolute",
    left: 0,
    width: "100%",
    padding: "0rem 1rem",
    "@media(max-width : 1200px)": {
      position: "inherit",
      gap: "10px",
      marginTop: "0rem",
      "@media(max-width : 900px)": {
        flexWrap: "wrap",
        justifyContent: "left",
        gap: "1rem",
      },
    },
  },
  join_over: {
    paddingTop: "1.6rem",
  },
});

const HappyClient = () => {
  const [isShown, setIsShown] = useState(false);
  const [isShown1, setIsShown1] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const [isShown3, setIsShown3] = useState(false);
  const [isShown4, setIsShown4] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box className={classes.Main__div}>
        <Container>
          <Box className={classes.client__box_item}>
            <Link
              data-aos="fade-up"
              data-aos-duration="500"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              className={classes.c_box_one}
            >
              {isShown ? (
                <Typography
                  component={"img"}
                  src={web_icon}
                  sx={{ width: "60px", height: "60px" }}
                />
              ) : (
                <Typography
                  component={"img"}
                  src={web_icon}
                  sx={{ width: "60px", height: "60px" }}
                />
              )}
              <Typography mt={1} variant="h6" fontWeight={600}>
                Web Development
              </Typography>
            </Link>

            <Link
              data-aos="fade-down"
              data-aos-duration="500"
              onMouseEnter={() => setIsShown1(true)}
              onMouseLeave={() => setIsShown1(false)}
              className={classes.c_box_one}
            >
              {isShown1 ? (
                <Typography
                  component={"img"}
                  src={software_testing}
                  sx={{ width: "60px", height: "60px" }}
                />
              ) : (
                <Typography
                  component={"img"}
                  src={software_testing}
                  sx={{ width: "60px", height: "60px" }}
                />
              )}
              <Typography mt={1} variant="h6" fontWeight={600}>
                Software Testing
              </Typography>
            </Link>

            <Link
              data-aos="fade-up"
              data-aos-duration="500"
              onMouseEnter={() => setIsShown2(true)}
              onMouseLeave={() => setIsShown2(false)}
              className={classes.c_box_one}
            >
              {isShown2 ? (
                <Typography
                  component={"img"}
                  src={web_3}
                  sx={{ width: "60px", height: "60px" }}
                />
              ) : (
                <Typography
                  component={"img"}
                  src={web_3}
                  sx={{ width: "60px", height: "60px" }}
                />
              )}
              <Typography mt={1} variant="h6" fontWeight={600}>
                Web 3 Development
              </Typography>
            </Link>

            <Link
              data-aos="fade-down"
              data-aos-duration="500"
              onMouseEnter={() => setIsShown3(true)}
              onMouseLeave={() => setIsShown3(false)}
              className={classes.c_box_one}
            >
              {isShown3 ? (
                <Typography
                  component={"img"}
                  src={block_chain}
                  sx={{ width: "60px", height: "60px" }}
                />
              ) : (
                <Typography
                  component={"img"}
                  src={block_chain}
                  sx={{ width: "60px", height: "60px" }}
                />
              )}
              <Typography mt={1} variant="h6" fontWeight={600}>
                Blockchain <Typography component={"br"} />
                Development
              </Typography>
            </Link>

            <Link
              data-aos="fade-up"
              data-aos-duration="500"
              onMouseEnter={() => setIsShown4(true)}
              onMouseLeave={() => setIsShown4(false)}
              className={classes.c_box_one}
            >
              {isShown4 ? (
                <Typography
                  component={"img"}
                  src={marketing}
                  sx={{ width: "60px", height: "60px" }}
                />
              ) : (
                <Typography
                  component={"img"}
                  src={marketing}
                  sx={{ width: "60px", height: "60px" }}
                />
              )}
              <Typography mt={1} variant="h6" fontWeight={600}>
                Marketing Services
              </Typography>
            </Link>

            <Link
              data-aos="fade-up"
              data-aos-duration="500"
              onMouseEnter={() => setIsShown4(true)}
              onMouseLeave={() => setIsShown4(false)}
              className={classes.c_box_one}
            >
              {isShown4 ? (
                <Typography
                  component={"img"}
                  src={devops}
                  sx={{ width: "60px", height: "60px" }}
                />
              ) : (
                <Typography
                  component={"img"}
                  src={devops}
                  sx={{ width: "60px", height: "60px" }}
                />
              )}
              <Typography mt={1} variant="h6" fontWeight={600}>
                DevOps Services
              </Typography>
            </Link>
          </Box>
          {/* <Box className={classes.join_over}>
            <Grid container spacing={2}>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <Box className={classes.clientHding}>
                  <Typography variant="h6" color={"#000"} fontWeight={600}>
                    Join over +15,000 happy clients!
                  </Typography>
                  <Box className={classes.line} />
                </Box>
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <Box className={classes.clients}>
                  <Link
                    to={"https://moonland-web.vercel.app/"}
                    target={"_blank"}
                  >
                    <Typography component={"img"} src={moonland} alt="" />
                  </Link>
                  <Link to={"https://zethan.io/"} target={"_blank"}>
                    <Typography component={"img"} src={zethan} alt="" />
                  </Link>
                  <Link to={"https://betterphysiology.com/"} target={"_blank"}>
                    <Typography
                      component={"img"}
                      src={betterphysiology}
                      alt=""
                    />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box> */}
        </Container>
      </Box>
    </>
  );
};

export default HappyClient;
