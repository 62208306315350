import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";

import axios from "axios";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { API_URl, SITE_KEY } from "../../components/config/config";

const useStyle = makeStyles({
  bin1: {
    width: "100%",
    border: "2px solid transparent !important",
    borderRadius: "0px ",
    background: "rgba(0, 0, 0, 0.07)",
    color: "#fff",
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "2px solid #222222 !important",
      borderRadius: "0px !important ",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& input": {
      padding: "8px",
      fontSize: "17px",
      borderRadius: "10px",
      fontWeight: "500",
     
      color: "rgb(16 16 16 / 80%)",
      "&::placeholder": {
        color: "#0000008f !important",
       
        fontSize: "16px",
      },
    },
    "& textarea": {
      // background: "#F7F7F9",
      padding: "10px !important",
      color: "#000",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "#0000008f",
      },
    },
  },
  error: {
    margin: "0.1rem 0",
    fontSize: "14px !important",
    color: "red !important",
    textAlign: "start",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px 0", color: "#800080" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#fff",
            top: "-10px",
            background: "#3E71E3",
            // border:"1px solid #800080",
            borderRadius: "100%",
            "&:hover": {
              background: "#3E71E3",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ContactForm = ({ display }) => {
  const [recaptcha, setRecaptcha] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    window.recaptchaCallback = function () {
      setRecaptcha(true);
    };
  }, []);
  const classes = useStyle();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const getInitialValues = () => {
    return {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      message: "",
    };
  };
  const validationSchema = Yup.object({
    fname: Yup.string()
      .trim("Fisrt Name should not contain spaces")
      .required("Required"),
    lname: Yup.string()
      .trim("Last Name should not contain spaces")
      .required("Required"),
    email: Yup.string()
      .trim("Email should not contain spaces")
      .email("Invalid email")
      .required("Required"),
    message: Yup.string()
      .trim("Message should not contain spaces")
      .required("Required"),
    phone: Yup.number().required("Required"),
  });
  const initialValues = getInitialValues();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (!recaptcha) {
        setRecaptchaError(true); // Set recaptcha error if not ticked
      } else {
      try {
        setLoading(true);
        const res = await axios.post(
          `${API_URl}/register`,
          values,
          config
        );
        if (res.status === 200) {
          setShowSnackbar(true);
          setTimeout(() => {
            resetForm();
            setLoading(false);
            setShowSnackbar(false);
          }, 2000);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
    },
  });

  return (
    <Container id="contact">
      <BootstrapDialog
        onClose={() => setShowSnackbar(false)}
        aria-labelledby="customized-dialog-title"
        open={showSnackbar}
        sx={{
          "& .MuiPaper-root": {
            overflowY: "inherit",
          },
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "1222px",
            // background: "#E4D5F4",
            border: "2px solid #3E71E3",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowSnackbar(false)}
        >
          <Typography
            sx={{
              color: "green",
              fontSize: "1.2rem",
              borderBottom: "1px solid #3E71E3",
              pb: "0.3rem",
              mb: "0.3rem",
              display: "flex",
              alignItems: "center",
              gap: "0.1rem",
             
            }}
          >
            <TaskAltIcon sx={{ mb: "2px" }} />
            Success
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              textAlign: "center",
              py: "1.5rem",
            //   fontSize: "1.2rem",
              color: "green",
             
            }}
          >
           Thanks for showing interest,<br/> will get back to you
          </Box>
        </DialogContent>
      </BootstrapDialog>
      <Grid container spacing={3} mt="0rem">
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{ display: display ? display : "block" }}
        >
          <Box
            sx={{
              mt: { xs: "2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "40px", xs: "25px" },
                textAlign: { sm: "center", xs: "center" },
                // fontFamily: "lucidaBrightBold",
                fontWeight: "600",
              }}
            >
              Get in Touch
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                width: { lg: "60%", sm: "75%", xs: "100%" },
                margin: "0 auto 3rem",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                     
                      color: "rgb(16 16 16 / 80%)",
                    }}
                  >
                    First Name<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="fname"
                    name="fname"
                    type="text"
                    className={classes.bin1}
                    placeholder="Enter Your First Name"
                    variant="standard"
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.fname && formik.errors.fname && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.fname}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                     
                      color: "rgb(16 16 16 / 80%)",
                    }}
                  >
                    Last Name<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="lname"
                    name="lname"
                    type="text"
                    className={classes.bin1}
                    placeholder="Enter Your Last Name"
                    variant="standard"
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lname && formik.errors.lname && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.lname}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                     
                      color: "rgb(16 16 16 / 80%)",
                    }}
                  >
                    Email<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    className={classes.bin1}
                    placeholder="Enter Your Email"
                    variant="standard"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                     
                      color: "rgb(16 16 16 / 80%)",
                    }}
                  >
                    Phone<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="phone"
                    name="phone"
                    type="number"
                    className={classes.bin1}
                    placeholder="Enter Your Phone No."
                    variant="standard"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.phone}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                     
                      color: "rgb(16 16 16 / 80%)",
                    }}
                  >
                    Message<sup style={{ color: "red" }}>*</sup>
                  </Typography>

                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="message"
                    name="message"
                    type="text"
                    className={classes.bin1}
                    placeholder="Message"
                    variant="standard"
                    multiline={5}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.message}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                      fontFamily: "Nunito",
                      color: "rgb(16 16 16 / 80%)",
                    }}
                  >
                    This site is protected by <b>reCAPTCHA</b> and the Google{" "}
                    <b>Privacy Policy</b> and <b>Terms of Service</b> apply.
                  </Typography>
                </Grid>
              </Grid>
              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="g-recaptcha"
                data-sitekey={SITE_KEY}
                data-callback="recaptchaCallback"
              ></div>
              {!recaptcha && recaptchaError && (
                <Typography
                  sx={{ textAlign: "center !important" }}
                  className={classes.error}
                >
                  Please tick above recaptcha.
                </Typography>
              )}
              <Box textAlign={"center"}>
                <Button
                  disableRipple
                  type="submit"
                  sx={{
                    background:
                      "linear-gradient(145deg, #3E71E3, #591EAC) !important",
                    mt: "1.5rem",
                    color: "#fff !important",
                    width: { md: "150px", xs: "150px" },
                    fontSize: "18px !important",
                    textTransform: "capitalize !important",
                    padding: "10px 25px !important",
                    lineHeight: "initial !important",
                    fontWeight: "600",
                    transition: "0.5s all !important",
                    border: "1px solid #008080 !important",
                    "&:hover": {
                      background:
                        "linear-gradient(145deg, #3E71E3, #591EAC) !important",
                      color: "#fff !important",
                    },
                    borderRadius: "8px !important",
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        width: "20px !important",
                        height: "20px !important",
                        color: "#e4dbe7",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactForm;
