import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AOS from "aos";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import * as yup from "yup";
import popup_img from "../components/images/popup_img.png";
import { API_URl, SITE_KEY } from "../components/config/config";
import axios from "axios";

const useStyles = makeStyles({
  TextField__box: {
    border: "1px solid black !important",
    padding: "0.1rem 0.3rem !important",
    "@media (max-width:900px)": {
      padding: "0.4rem 0.3rem",
    },
    borderRadius: "5px",
  },
  TextField__box2: {
    border: "1px solid black !important",
    padding: "0.1rem 0.3rem !important",
    borderRadius: "5px",

    "@media (max-width:900px)": {
      padding: "0.4rem 0.3rem",
    },
  },
  btn__bg: {
    "@media(max-width : 900px)": {
      marginTop: "2rem",
    },
  },
  contact___btn: {
    backgroundColor: "transparent !important",
    fontFamily: "poppins !important",
    padding: "0.6rem 2rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
    },
  },
  logo_wrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo__box: {
    backgroundColor: "#fff",
    padding: "0.6rem 1rem 0rem 1rem",
    borderRadius: "12px",
    margin: "0rem 1.5rem",
    opacity: "0.9",
  },
  error: {
    color: "red",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
});

const PopUpModal = ({
  open,
  handleClose,
  handleSuccessOpen,
  handleMessage,
}) => {
  const [recaptcha, setRecaptcha] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    window.recaptchaCallback = function () {
      setRecaptcha(true);
    };
  }, [handleSuccessOpen]);
  const classes = useStyles();

  // const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [scroll, setScroll] = React.useState("paper");


  const validationSchema = yup.object({
    fname: yup.string().required("Required").trim(),
    lname: yup.string().required("Required").trim(),
    email: yup
      .string()
      .required("Required")
      .trim()
      .test("Please enter valid email", function (value) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      }),
    phone: yup
      .string()
      .trim("Phone number should not contain spaces")
      .required("Required")
      .length(10, "Phone number should be exactly 10 digits")
      // .min(10, "Phone number should be exactly 10 digits") // Minimum length
      // .max(10, "Phone number should be exactly 10 digits")
      .test("is-numeric", "Please enter a numeric value", function (value) {
        // Check if the value is numeric
        return /^[0-9]+$/.test(value);
      }),
    message: yup.string().required("Required").trim(),
    check: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      message: "",
      check: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!recaptcha) {
        setRecaptchaError(true); // Set recaptcha error if not ticked
      } else {
      let url = `${API_URl}/register`;
      axios
        .post(url, values)
        .then((response) => {
          if (response.status === 200) {
            handleClose();
            handleMessage(<>Thanks for showing interest,<br/> will get back to you</>);
            handleSuccessOpen();
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error.message);
        });
      resetForm();
    }}
  });

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* <Box className={classes.btn__bg}>
        <Button
          data-aos="fade-left"
          data-aos-duration="500"
          onClick={handleClickOpen("body")}
          variant="contained"
          className={classes.contact___btn}
        >
          Get Connected
        </Button>
      </Box> */}

      <Box position={"relative"}>
        <Dialog
          disableScrollLock={true}
          sx={{
            backgroundColor: "#0000003b",

            "& .MuiPaper-root": {
              border: "1px solid #002cffbd",
              width: "1200px",
              borderRadius: "10px",
              background: "linear-gradient(to top, #4c4a4a, #c1bebe, white)",
              "@media(max-width:900px)": {
                width: "500px",
              },
            },
            "& .MuiDialog-paper": {
              maxWidth: "700px",
            },
          }}
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent dividers={scroll === "paper"}>
            <Box>
              <IconButton
                sx={{
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                  zIndex: "1200",
                }}
                title="Close"
                onClick={handleClose}
              >
                <ClearIcon sx={{ color: "black" }} />
              </IconButton>
              <Box textAlign={"center"}>
                <Typography component={"img"} src={popup_img} width={"50%"} />
                <Typography
                  variant="h6"
                  fontWeight={700}
                  sx={{ color: "black", p: "10px" }}
                >
                  Want to avail free expert consultation,
                  <br /> You are just one step away !
                </Typography>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1.5}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <Typography
                        component={"label"}
                        sx={{
                          fontWeight: 600,
                          padding: "0.3rem 0rem",
                        }}
                      >
                        First Name
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        name="fname"
                        value={formik.values.fname}
                        placeholder="Enter Your First Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          // style: { color: "white" },
                          disableUnderline: true,
                        }}
                        className={classes.TextField__box}
                      />
                      {formik.touched.fname && formik.errors.fname && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.fname}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <Typography
                        component={"label"}
                        sx={{
                          fontWeight: 600,
                          padding: "0.3rem 0rem",
                        }}
                      >
                        Last Name
                      </Typography>
                      <TextField
                        className={classes.TextField__box}
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        name="lname"
                        value={formik.values.lname}
                        placeholder="Enter Your Last Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                      {formik.touched.lname && formik.errors.lname && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.lname}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <Typography
                        component={"label"}
                        sx={{
                          fontWeight: 600,
                          padding: "0.3rem 0rem",
                        }}
                      >
                        Email
                      </Typography>
                      <TextField
                        className={classes.TextField__box}
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        type="email"
                        name="email"
                        value={formik.values.email}
                        placeholder="Enter Your Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.email}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <Typography
                        component={"label"}
                        sx={{
                          fontWeight: 600,
                          padding: "0.3rem 0rem",
                        }}
                      >
                        Phone No.
                      </Typography>
                      <TextField
                        className={classes.TextField__box}
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        name="phone"
                        type="number"
                        value={formik.values.phone}
                        placeholder="Enter Your Number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            maxLength: 10,
                          },
                        }}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.phone}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      <Typography
                        component={"label"}
                        sx={{
                          fontWeight: 600,
                          padding: "0.3rem 0rem",
                        }}
                      >
                        Message
                      </Typography>
                      <TextField
                        className={classes.TextField__box2}
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={2}
                        maxRows={3}
                        name="message"
                        value={formik.values.message}
                        placeholder="Message...."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                      {formik.touched.message && formik.errors.message && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.message}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Box>
                        <Checkbox
                          {...label}
                          name="check"
                          value={formik.values.check}
                          placeholder="Message...."
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Box>
                      <Box>
                        <Typography color={"#000"} fontSize={"14px"}>
                          I Agree to himotech collecting & processing my
                          personal data to allow me to receive information on
                          himotech services. For further information, please see
                          our Privacy Notice.{" "}
                        </Typography>
                      </Box>
                    </Box>
                    {formik.touched.check && formik.errors.check && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.check}
                      </Typography>
                    )}
                    <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                      fontFamily: "Nunito",
                      color: "rgb(16 16 16 / 80%)",
                    }}
                  >
                    This site is protected by <b>reCAPTCHA</b> and the Google{" "}
                    <b>Privacy Policy</b> and <b>Terms of Service</b> apply.
                  </Typography>
                  </Grid>
                </Grid>
                <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="g-recaptcha"
                data-sitekey={SITE_KEY}
                data-callback="recaptchaCallback"
              ></div>
              {!recaptcha && recaptchaError && (
                <Typography
                  sx={{ textAlign: "center !important" }}
                  className={classes.error}
                >
                  Please tick above recaptcha.
                </Typography>
              )}
                <Box
                  textAlign={"center"}
                  sx={{
                    textAlign: "center",
                    padding: "0.5rem 0rem",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      borderRadius: "20px",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default PopUpModal;
