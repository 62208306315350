import web_icon from "../components/images/icons8-web-development-64.png";
import software_testing from "../components/images/icons8-software-testing-64.png";
import block_chain from "../components/images/icons8-block-chain-64.png";
import marketing from "../components/images/one_marketing.png";
import devops from "../components/images/devops-60.png";
import web_3 from "../components/images/icons8-3-season-50.png";

const popContent = [
  {
    id: 1,
    title: "WEB DEVELOPMENT",
    content: (
      <>
        <li>
          Web development plays a critical role in the digital era, enabling
          businesses to thrive in a competitive market by embracing
          technological advancements.
        </li>
        <br/>
        <li>
          It encompasses the creation, construction, and maintenance of websites
          and web applications accessible via internet browsers.
        </li>
      </>
    ),
    modalContent: (
      <>
        <li>
          Web development plays a critical role in the digital era, enabling
          businesses to thrive in a competitive market by embracing
          technological advancements.
        </li>
        <br/>
        <li>
          It encompasses the creation, construction, and maintenance of websites
          and web applications accessible via internet browsers.
        </li>
        <br/>
        <li>
          Web development involves web design, programming, and database
          management, resulting in robust online platforms.
        </li>
        <br/>
        <li>
          As an established IT solutions provider, we have been delivering
          high-quality web development services since 2003.
        </li>
        <br/>
        <li>
          Our experienced team specializes in crafting bespoke solutions
          tailored to meet the diverse needs of our clients.
        </li>
        <br/>
        <li>
          In addition to development, we prioritize enhancing our products'
          visibility and organic traffic through strategic SEO practices.
        </li>
        <br/>
        <li>
          We offer a wide range of services including software deployment,
          blockchain development, WEB3 services, online marketing solutions, and
          troubleshooting support.
        </li>
        <br/>
        <li>
          Our commitment to providing customized IT solutions underscores our
          dedication to empowering businesses with a distinct technological
          advantage.
        </li>
      </>
    ),
    icon: web_icon,
    show: true,
  },
  {
    id: 2,
    title: "SOFTWARE TESTING",
    content: (
      <>
        <li>
          Himotech emphasizes the importance of aligning development with its
          intended functionality for effective outcomes.
        </li>
        <br/>
        <li>
          We ensure a seamless experience throughout both the development and
          post-development phases.
        </li>
      </>
    ),
    modalContent: (
      <>
        <li>
          Himotech emphasizes the importance of aligning development with its
          intended functionality for effective outcomes.
        </li>
        <br/>
        <li>
          We ensure a seamless experience throughout both the development and
          post-development phases.
        </li>
        <br/>
        <li>
          From UI/UX design to rigorous 3-stack working verification, we
          meticulously address every requirement, considering client demands and
          preferences.
        </li>
        <br/>
        <li>
          Our testing experts, boasting years of experience, efficiently manage
          all testing procedures to deliver 100% bug-free and smoothly executed
          services.
        </li>
        <br/>
        <li>
          Our testing experts, boasting years of experience, efficiently manage
          all testing procedures to deliver 100% bug-free and smoothly executed
          services.
        </li>
        <br/>
        <li>
          We prioritize providing superior Manual and Automation testing
          services to ensure error-free product delivery and greater automation.
        </li>
        <br/>
        <li>
          This approach not only guarantees zero bugs but also elevates your
          product's SEO priority.
        </li>
        <br/>
        <li>
          Rest assured, with Himotech, your development process is in capable
          hands.
        </li>
      </>
    ),
    icon: software_testing,
  },
  {
    id: 3,
    title: "WEB 3",
    content: (
      <>
        <li>
          Centralization has facilitated the mass adoption of the World Wide
          Web, establishing a robust infrastructure that has served billions of
          users.
        </li>
        <br/>
        <li>
          However, this centralization has also led to a concentration of power
          in the hands of a few entities, dictating the content and rules of the
          internet.
        </li>
      </>
    ),
    modalContent: (
      <>
        <li>
          Centralization has facilitated the mass adoption of the World Wide
          Web, establishing a robust infrastructure that has served billions of
          users.
        </li>
        <br/>
        <li>
          However, this centralization has also led to a concentration of power
          in the hands of a few entities, dictating the content and rules of the
          internet.
        </li>
        <br/>
        <li>
          Web3 emerges as a solution to this issue by advocating for
          decentralization, where users play a pivotal role in building,
          operating, and owning the internet.
        </li>
        <br/>
        <li>
          Unlike the current model dominated by tech giants, Web3 aims to
          empower individuals rather than corporations.
        </li>
        <br/>
        <li>
          It encompasses a new vision for the internet, centered around
          principles of decentralization and user ownership.
        </li>
        <br/>
        <li>
          At its core, Web3 leverages technologies such as blockchains,
          cryptocurrencies, and NFTs to democratize ownership and give users
          greater control over their online experiences.
        </li>
      </>
    ),
    icon: web_3,
  },
  {
    id: 4,
    title: "BLOCKCHAIN",
    content: (
      <>
        <li>
          Blockchain technology offers greater transparency, instant
          traceability, increased efficiency, and high security for businesses.
        </li>
        <br/>
        <li>
          It enables the creation of an immutable ledger for tracking orders,
          payments, accounts, and other transactions.
        </li>
      </>
    ),
    modalContent: (
      <>
        <li>
          Blockchain technology offers greater transparency, instant
          traceability, increased efficiency, and high security for businesses.
        </li>
        <br/>
        <li>
          It enables the creation of an immutable ledger for tracking orders,
          payments, accounts, and other transactions.
        </li>
        <br/>
        <li>
          This advanced database mechanism facilitates transparent information
          sharing within a business network.
        </li>
        <br/>
        <li>
          Blockchain systems come with built-in mechanisms to prevent
          unauthorized transaction entries and ensure consistency in the shared
          view of transactions.
        </li>
        <br/>
        <li>
          Our team has years of expertise in providing immutability, consensus,
          and decentralization to our clients through Blockchain technology.
        </li>
        <br/>
        <li>
          We assist our clients in implementing Blockchain to establish a
          non-manipulative and immutable database structure that ensures secure
          sharing of information.
        </li>
      </>
    ),
    icon: block_chain,
  },
  {
    id: 5,
    title: "MARKETING",
    content: (
      <>
        <li>
          Apart from development and operations, we engage in comprehensive
          market analysis and competitor research.
        </li>
        <br/>
        <li>
          We define your firm's market positioning, pricing strategies, and
          services to ensure optimal market penetration.
        </li>
      </>
    ),
    modalContent: (
      <>
        <li>
          Apart from development and operations, we engage in comprehensive
          market analysis and competitor research.
        </li>
        <br/>
        <li>
          We define your firm's market positioning, pricing strategies, and
          services to ensure optimal market penetration.
        </li>
        <br/>
        <li>
          Our focus lies in promoting your products and services to the target
          audience, highlighting the benefits of working with your company.
        </li>
        <br/>
        <li>
          We strive to drive demand and adoption of your products among existing
          customers.
        </li>
        <br/>
        <li>
          Through strategic marketing initiatives, we guide potential customers
          through the purchasing journey.
        </li>
        <br/>
        <li>
          We leverage social media and user interaction platforms to implement
          effective marketing strategies.
        </li>
        <br/>
        <li>
          Our aim is to establish a meaningful social media presence for your
          business and its products, enhancing brand visibility and engagement.
        </li>
      </>
    ),
    icon: marketing,
  },
  {
    id: 6,
    // title: "DevOps Services",
    title: "DEVOPS SERVICES",
    content: (
      <>
        <li>
          DevOps adoption is increasingly prevalent in the IT industry, with
          organizations like Amazon, Netflix, Walmart, and Facebook embracing
          this approach.
        </li>
        <br/>
        <li>
          DevOps is characterized by a blend of cultural philosophies,
          practices, and tools aimed at enhancing an organization's ability to
          deliver applications and services rapidly.
        </li>
      </>
    ),
    modalContent: (
      <>
        <li>
          DevOps adoption is increasingly prevalent in the IT industry, with
          organizations like Amazon, Netflix, Walmart, and Facebook embracing
          this approach.
        </li>
        <br/>
        <li>
          DevOps is characterized by a blend of cultural philosophies,
          practices, and tools aimed at enhancing an organization's ability to
          deliver applications and services rapidly.
        </li>
        <br/>
        <li>
          Organizations utilizing DevOps experience faster product delivery and
          evolution compared to those following traditional software development
          and infrastructure management processes.
        </li>
        <br/>
        <li>
          We specialize in delivering top-tier DevOps services, ensuring prompt
          and efficient product delivery while optimizing resource utilization.
        </li>
        <br/>
        <li>
          Our DevOps services promote greater automation, streamlining processes
          for improved efficiency.
        </li>
        <br/>
        <li>
          We facilitate seamless coordination between development and operations
          teams, leveraging DevOps methodologies to enhance collaboration and
          productivity.
        </li>
      </>
    ),
    icon: devops,
  },
];

export default popContent;
